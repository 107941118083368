import React, { useCallback, useEffect, useState } from 'react';
import SearchBar from 'src/components/Basicos/SearchBar';
import { IoOptionsOutline } from "react-icons/io5";
import { FiEye } from "react-icons/fi";
import iconSingle from "src/assets/icones/icon-single.svg"
import ThreeDotsIcon from "src/assets/icones/three-dots.svg"
import iconDouble from "src/assets/icones/icon-double.svg"
import iconGroup from "src/assets/icones/icon-group.svg"
import './style.css';
import { Link } from 'react-router-dom';
import { converterDataBr } from 'src/components/Basicos/funcoes';
import ModalFiltroMobile from '../../../components/Funcionalidades/ModalFiltroMobile';
import { useControlContextCadastro } from 'src/contexts/CadastroControlContext';
import { useControlContextMobile } from 'src/contexts/MobileContext';
import ModalCancelarMobile from 'src/components/Funcionalidades/ModalCancelarMobile';

type Props = {
    data: any
}

const IndexMobile: React.FunctionComponent<Props> = ({
    data,
}) => {
    const [value, setValue] = useState<String>('');
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [dadosFiltrados, setDadosFiltrados] = useState<any>(data);
    const [modalCancelar, setModalCancelar] = useState<boolean>(false);
    const [locatarioCancelar, setLocatarioCancelar] = useState<any>();
    const { loadEtapas, loadStatusEtapa } = useControlContextCadastro();
    const { mobile, setActiveSideBar } = useControlContextMobile();

    const onChange = useCallback(() => {
        if (value !== undefined || value !== '') {
            var filtro = value.toLowerCase();
            var clientesFiltrados = data.filter(function (datt: any) {
                // Adicione uma verificação para garantir que datt.nome não seja undefined
                const nomeLowerCase = (datt.informacoes_basicas.nome_cliente || '').toLowerCase();
                return nomeLowerCase.includes(filtro);
            });

            setDadosFiltrados(clientesFiltrados);
        }
    }, [value, data]);

    const changeFiltro = (item: any, action: any) => {
        console.log(item, action)
    }

    const openCloseModal = () => {
        setOpenModal(!openModal);
    }

    const openModalCancelar = (locatarioId: any) => {
        setLocatarioCancelar(locatarioId);
        setModalCancelar(!modalCancelar);
    }

    const closeModalCancelar = (locatarioId: any) => {
        setModalCancelar(false);
        setLocatarioCancelar(undefined);
    }

    useEffect(() => {
        loadEtapas();
        loadStatusEtapa();
        setActiveSideBar(false);
    }, [mobile]);

    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            onChange();
        }, 300); // Tempo de debounce em milissegundos

        return () => {
            clearTimeout(debounceTimeout);
        };
    }, [value, onChange]);

    const iconLocatario = (qtdLocatario: any) => {
        switch (qtdLocatario) {
            case 1:
                return iconSingle;
            case 2:
                return iconDouble;
            default:
                return iconGroup;
        }
    }

    return (
        <>
            <ModalFiltroMobile
                isOpen={openModal}
                isClose={false}
                closeOnOutsideClick={true}
                lockScroll={false}
                openCloseModal={openCloseModal}
                changeFiltro={changeFiltro}
            />
            <ModalCancelarMobile
                isOpen={modalCancelar}
                isClose={false}
                closeModalCancelar={closeModalCancelar}
                closeOnOutsideClick={true}
                lockScroll={false}
                changeFiltro={changeFiltro}
                locatarioId={locatarioCancelar}
            />
            <div className='filtros-locatario-mobile'>
                <SearchBar
                    isButton={false}
                    value={value}
                    setValue={setValue}
                    onChange={onChange}
                    placeholder={'Locatário'}
                    inputTitle={''}
                    dates={false}
                />
                <div className='button-filtro' onClick={() => openCloseModal()}>
                    <IoOptionsOutline />
                    Filtros
                </div>
            </div>
            {
                dadosFiltrados?.map((locatario: any) => {
                    return (
                        <div className='card-cadastro-mobile'>
                            <div className='header-cadastro-mobile'>
                                <div className='icon-etapa-mobile'>
                                    <img className="inset-0 h-6 w-6 object-center" alt="icon_group" src={iconLocatario(locatario?.locatarios_count)} />
                                    <div className='etapa-cadastro-mobile'>
                                        {locatario?.ultimo_historico.etapa_historico.descricao}
                                    </div>
                                </div>
                                <div className='icon-olho-ponto'>
                                    <Link
                                        to={`/cadastro/locatario/detalhes/${locatario?.id}`}
                                        onClick={() => undefined}
                                        className="flex ml-auto w-8 h-8 text-white bg-gray-ligth-system-mega-imoveis border border-gray-system-mega-imoveis p-2 focus:outline-none rounded-xl items-center justify-center">
                                        <FiEye
                                            size={'1.2rem'}
                                            className={`text-gray-system-mega-imoveis`} />
                                    </Link>
                                    <img className="icon-delete-client-mobile" onClick={() => openModalCancelar(locatario?.id)} alt="icon_crown" src={ThreeDotsIcon} />
                                </div>
                            </div>
                            <div>
                                <h1 className='mt-2 text-gray-system-mega-imoveis text-lg'>{locatario?.informacoes_basicas.nome_cliente}</h1>
                                <div className='info-cadastro-mobile'>
                                    <div>
                                        <h1 className='text-gray-system-mega-imoveis info-font-mobile'>{locatario?.informacoes_basicas.telefone1}</h1>
                                        <h1 className='text-gray-system-mega-imoveis info-font-mobile'>{locatario?.imovel_pretendido?.imovel?.codigo_imovel}</h1>
                                    </div>
                                    <div className='barra-info-mobile ml-2 mr-2'></div>
                                    <div>
                                        <h1 className='text-gray-system-mega-imoveis info-font-mobile'>{locatario?.garantia?.descricao}</h1>
                                        <h1 className='text-gray-system-mega-imoveis info-font-mobile'>{locatario?.sede_responsavel?.nome}</h1>
                                    </div>
                                </div>
                            </div>
                            <div className='card-footer-mobile mt-2'>
                                <h1 className='text-gray-system-mega-imoveis info-font-mobile'>{converterDataBr(locatario?.created_at)}</h1>
                                <h1 className='text-gray-system-mega-imoveis info-font-mobile'>Em atendimento há {locatario?.tempo_atendimento} dias</h1>
                            </div>
                        </div>
                    )
                })
            }

        </>
    );
}

export default IndexMobile;