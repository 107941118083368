import React, { Fragment, useState } from 'react';
import Modal from '../ModalNoticia';
import './style.css';
import { axiosApi } from 'src/services/axiosInstance';
import { formatarValor } from 'src/components/Basicos/funcoes';
import iconLupa from "src/assets/icones/icon-lupa.svg"

type Props = {
    open: boolean;
    isClose?: any;
    locatarioId?: any;
    setImovelModal?: any;
}

const ModalTrocarImovel: React.FC<Props> = ({
    isClose,
    open,
    locatarioId,
    setImovelModal
}: Props): JSX.Element => {
    const [imovel, setImovel] = useState<any>();
    const [naoEncontrado, setNaoEncontrado] = useState<boolean>(false);

    const pesquisarImovel = async (event: any) => {
        let codigoImovel = event.target.value;
        try {
            if (open && event.target.value != "") {
                const { data } = await axiosApi.get(`/codigo-imovel/${codigoImovel}`);
                console.log(data)
                setImovel(data.data)
            }
        } catch (error) {
            console.log(error);
            setNaoEncontrado(true);
        }
    }

    const salvarAlteracao = async () => {
        if (imovel != undefined) {
            var params = {
                locatario_id: locatarioId,
                imovel_id: imovel?.id
            }
            const { data } = await axiosApi.put(`trocar-imovel/`, params);
            setImovel(undefined)
            setImovelModal(data.data)
            isClose(data)
        }
    }

    const zerarModal = () => {
        setImovel(undefined);
        setNaoEncontrado(false)
        isClose()
    }

    return (
        <Modal
            isOpen={open}
            isClose={() => zerarModal()}
            headerStyle={''}
            closeOnOutsideClick={true}
            widthModal={`35%`}
            modalStyle={`modal-style bg-white rounded-lg py-5 px-10 modal-usuario`}
        >
            <div className={`text-left font-extrabold text-xl modal-trocar-imovel`}>
                <span className="font-mont-bold font-color-dark-blue">
                    Trocar Imóvel
                </span>
                <div className='form-trocar-imovel'>
                    <span className="float-left text-base">
                        Código do novo imóvel
                    </span>
                    <textarea
                        rows={4}
                        // onChange={onChangeMotivo}
                        onBlur={pesquisarImovel}
                        // onBlur={pesquisarImovel(event?.target)}
                        required
                        name={`comentario_fiador`}
                        style={{ maxHeight: "2.5rem", minHeight: "2rem" }}
                        className="block p-1 w-full h-32 mt-2 text-sm border-2 border-opacity-100 text-gray-system-mega-imoveis border-gray-system-mega-imoveis rounded-lg focus:outline-none placeholder-gray-700 resize-none"
                        placeholder={`Digite o código do novo imóvel`}
                    />
                </div>
                {imovel != undefined ?
                    <>
                        <div>
                            <div className='flex'>
                                <FormaLabelInput
                                    titulo="Finalidade"
                                    nome_do_campo="id"
                                    valor_campo={imovel?.finalidade ?? ""}
                                    tipo_campo="text"
                                    width="98%"
                                />
                                <FormaLabelInput
                                    titulo="Sede Responsável"
                                    nome_do_campo="id"
                                    valor_campo={imovel?.sede?.nome ?? ""}
                                    tipo_campo="text"
                                    width="98%"
                                />
                            </div>
                            <div className='flex'>
                                <FormaLabelInput
                                    titulo="Logradouro"
                                    nome_do_campo="id"
                                    valor_campo={imovel?.endereco ?? ""}
                                    tipo_campo="text"
                                    width="98%"
                                />
                                <FormaLabelInput
                                    titulo="Número"
                                    nome_do_campo="id"
                                    valor_campo={imovel?.numero ?? ""}
                                    tipo_campo="text"
                                    width="98%"
                                />
                            </div>
                            <div className='flex'>
                                <FormaLabelInput
                                    titulo="Complemento"
                                    nome_do_campo="id"
                                    valor_campo={imovel?.complemento ?? ""}
                                    tipo_campo="text"
                                    width="100%"
                                />
                            </div>
                            <div className='flex'>
                                <FormaLabelInput
                                    titulo="Bairro"
                                    nome_do_campo="id"
                                    valor_campo={imovel?.bairro ?? ""}
                                    tipo_campo="text"
                                    width="100%"
                                />
                            </div>
                            <div className='flex'>
                                <FormaLabelInput
                                    titulo="Cidade"
                                    nome_do_campo="id"
                                    valor_campo={imovel?.cidade ?? ""}
                                    tipo_campo="text"
                                    width="98%"
                                />
                                <FormaLabelInput
                                    titulo="Estado"
                                    nome_do_campo="id"
                                    valor_campo={imovel?.estado ?? ""}
                                    tipo_campo="text"
                                    width="98%"
                                />
                            </div>
                            <div className='flex'>
                                <FormaLabelInput
                                    titulo="Aluguel"
                                    nome_do_campo="id"
                                    valor_campo={formatarValor(imovel?.preco_locacao, "dinheiro") ?? ""}
                                    tipo_campo="text"
                                    width="98%"
                                />
                                <FormaLabelInput
                                    titulo="IPTU"
                                    nome_do_campo="id"
                                    valor_campo={formatarValor(imovel?.iptu, "dinheiro") ?? ""}
                                    tipo_campo="text"
                                    width="98%"
                                />
                                <FormaLabelInput
                                    titulo="Condomínio"
                                    nome_do_campo="id"
                                    valor_campo={formatarValor(imovel?.condominio, "dinheiro") ?? ""}
                                    tipo_campo="text"
                                    width="98%"
                                />
                            </div>
                        </div>
                    </>
                    : naoEncontrado ?
                        <div className='imovel-nao-encontrado'>
                            <img className="inset-0 h-20 w-20 object-center" alt="icon_group" src={iconLupa} />
                            <h1 className='font-imovel-nao-econtrado'>Nenhum imóvel <span className='cor-nao-encontrado'>encontrado</span>.</h1>
                        </div>
                        : ""}
                <div className={`flex flex-row w-full justify-center mt-4 botoes-reset-senha`}>
                    <button
                        type={`button`}
                        className={`flex w-full h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs rounded-lg justify-center items-center tamanho-botao-reset ${imovel == undefined ? 'botao-bloqueado-imovel-ne' : "bg-white text-blue-mega-imoveis cursor-pointer botao-reset-senha"}`}
                        onClick={() => isClose()}
                    >
                        Cancelar
                    </button>
                    <button
                        type={`button`}
                        onClick={() => salvarAlteracao()}
                        className={`flex w-full h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs text-white cursor-pointer rounded-lg justify-center items-center tamanho-botao-reset ${imovel == undefined ? 'botao-bloqueado-imovel-ne' : "bg-blue-mega-imoveis"}`}
                    >
                        Confirmar
                    </button>
                </div>

            </div>
        </Modal>
    );
}

interface CamposForm {
    titulo?: string,
    nome_do_campo?: string,
    valor_campo?: number | string,
    tipo_campo?: string,
    required?: boolean,
    funcao?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    width?: string,
    height?: string,
    marginLeft?: string,
    marginRigth?: string,
    displayBlock?: string,
}

const FormaLabelInput: React.FC<CamposForm> = (props) => {
    return (
        <Fragment>
            <div className='form-input-trocar-imovel' style={{ width: props.width }}>
                <label className="form-label-titulo my-3" style={{ display: props.displayBlock }}>{props.titulo}</label>
                <input
                    required={props.required ? true : false}
                    type={props.tipo_campo}
                    name={props.nome_do_campo}
                    onChange={props.funcao}
                    value={props.valor_campo ?? ""}
                    className="modal-input mb-3 p-1 text-base"
                    style={{ width: props.width, height: props.height, marginLeft: props.marginLeft, marginRight: props.marginRigth }}
                />
            </div>
        </Fragment>
    );
};

export default ModalTrocarImovel;