import React, { Fragment, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import InputValidate from "src/components/Basicos/Form/Input/inputValidate";
import { formatarValor } from "src/components/Basicos/funcoes";
import TitleValidate from "src/components/Basicos/TitleSelectedValidate";
import { mask } from "src/helpers/functions";
import { axiosApi } from "src/services/axiosInstance";
import FormularioCadastroFiadorMobile from "./indexMobile";
import { useControlContextMobile } from "src/contexts/MobileContext";

export type Props = {
    fiador?: any;
    campos?: any;
    handleClick?: any;
    selectSection?: any;
    sections?: any;
    onChange?: any;
    openModal?: any;
    itsView?: boolean;
    etapaId?: any;
}

const FormularioCadastroFiador: React.FunctionComponent<Props> = ({
    fiador,
    campos,
    handleClick,
    selectSection,
    sections,
    onChange,
    openModal,
    itsView,
    etapaId
}) => {
    const { fiadorId }: any = useParams();
    const history = useHistory();
    const { mobile, setMobile } = useControlContextMobile();

    const existFalse = Object.values(campos).filter(function (item: any) { return item === false }).length > 0;

    let nulos = [null, undefined];

    const statusNotButton = [2, 5];

    const rgOrCnh = fiador?.informacoes_basicas?.nacionalidade === null && fiador?.informacoes_basicas?.naturalidade === null ? 'RG' : 'CNH';

    const rgOrCnhConjuge = fiador?.conjuge?.informacoes_basicas?.nacionalidade === null && fiador?.conjuge?.informacoes_basicas?.naturalidade === null ? 'RG' : 'CNH';

    const nameInterpriseOrServicePublic = fiador?.atividade_profissional?.tipo_atividade_id === 5 ? 'Órgão Público' : 'Nome da Empresa';

    useEffect(() => {
        setMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    }, []);

    const handleStatusCadastro = async (status: string, etapa_id: number) => {
        try {
            if (fiador?.instrucao_reenvio !== "") {
                const data = {
                    fiador_id: fiadorId,
                    etapa_id: etapa_id,
                    comentario: null
                }
                await axiosApi.post(`fiador/status/${status}`, data);
                if (status === 'reprovado') {
                    history.goBack();
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const onSubmit = async (e: any) => {
        e.preventDefault();
        try {
            if (fiadorId !== null) {
                await axiosApi.post(`validacao/fiador/${fiadorId}?etapa=${etapaId}`, campos).then(async (response) => {
                    const data = {
                        instrucao_reenvio: fiador?.instrucao_reenvio
                    }
                    await axiosApi.put(`fiador-instrucao/${fiadorId}`, data);
                    history.goBack();
                });
            }

        } catch (error) {
            console.log(error);
        }
    }

    //No total são 71 campos para validar 

    // identificacao 11 campos    
    // conjuge 26 campos 
    // residencia atual 11 campos
    // atividade profissional 19 campos, quando cai na condição vira 7
    // imovel em garantia 2 campos

    const itsFull = () => {
        const size = Object.keys(campos ?? null).length;

        // quantidades de campos necessario para ser validado
        let qtdCamposGeral = 81;

        if (fiador != undefined) {
            if (nulos.includes(fiador?.informacoes_basicas?.nome_cliente)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.informacoes_basicas?.email)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.informacoes_basicas?.telefone1)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.informacoes_basicas?.telefone2)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.informacoes_basicas?.rg_cnh_frente?.nome)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.informacoes_basicas?.rg_cnh_verso?.nome)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.informacoes_basicas?.cpf)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.informacoes_basicas?.estado_civil?.descricao)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.informacoes_basicas?.qtd_dependentes)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.informacoes_basicas?.naturalidade)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.informacoes_basicas?.nacionalidade)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.informacoes_basicas.nome_cliente)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.informacoes_basicas.telefone1)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.informacoes_basicas.telefone2)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.informacoes_basicas?.rg_cnh_frente?.nome)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.informacoes_basicas?.rg_cnh_verso?.nome)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.informacoes_basicas.cpf)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.informacoes_basicas.naturalidade)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.informacoes_basicas.nacionalidade)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.informacoes_basicas.email)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.atividade_profissional?.tipo_atividade?.descricao)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.atividade_profissional?.renda_mensal)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.atividade_profissional?.nome_empresa)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.atividade_profissional?.tempo_empresa?.descricao)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.atividade_profissional?.cargo_atual)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.nome)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.atividade_profissional?.arquivo_contra_cheque?.nome)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.atividade_profissional?.arquivo_contra_cheque2?.nome)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.atividade_profissional?.arquivo_contra_cheque3?.nome)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.atividade_profissional?.arquivo_ctps?.nome)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.atividade_profissional?.arquivo_imposto_renda?.nome)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.atividade_profissional?.arquivo_extrato_bancario?.nome)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.atividade_profissional?.arquivo_extrato_bancario2?.nome)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.atividade_profissional?.arquivo_extrato_bancario3?.nome)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.atividade_profissional?.arquivo_outro?.nome)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.atividade_profissional?.endereco?.cep)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.atividade_profissional?.endereco?.logradouro)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.atividade_profissional?.endereco?.numero)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.atividade_profissional?.endereco?.bairro)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.atividade_profissional?.endereco?.cidade)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.atividade_profissional?.endereco?.estado)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.atividade_profissional?.endereco?.complemento)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.conjuge?.atividade_profissional?.ramo_atividade)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.residencia_atual?.tipo_residencia?.descricao)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.residencia_atual?.arquivo?.nome)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.endereco?.cep)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.endereco?.logradouro)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.endereco?.numero)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.endereco?.complemento)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.endereco?.bairro)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.endereco?.cidade)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.endereco?.estado)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.residencia_atual?.valor_aluguel)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.residencia_atual?.tempo_locacao)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.atividade_profissional?.tipo_atividade?.descricao)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.atividade_profissional?.outra_atividade_profissional)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.atividade_profissional?.ramo_atividade)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.atividade_profissional?.renda_mensal)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.nome)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.atividade_profissional?.arquivo_contra_cheque?.nome)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.atividade_profissional?.arquivo_contra_cheque2?.nome)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.atividade_profissional?.arquivo_contra_cheque3?.nome)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.atividade_profissional?.arquivo_ctps?.nome)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.atividade_profissional?.arquivo_imposto_renda?.nome)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.atividade_profissional?.arquivo_extrato_bancario?.nome)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.atividade_profissional?.arquivo_extrato_bancario2?.nome)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.atividade_profissional?.arquivo_extrato_bancario3?.nome)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.atividade_profissional?.arquivo_outro?.nome)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.atividade_profissional?.nome_empresa)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.atividade_profissional?.tempo_empresa?.descricao)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.atividade_profissional?.cargo_atual)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.atividade_profissional?.endereco?.cep)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.atividade_profissional?.endereco?.logradouro)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.atividade_profissional?.endereco?.numero)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.atividade_profissional?.endereco?.bairro)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.atividade_profissional?.endereco?.cidade)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.atividade_profissional?.endereco?.estado)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.atividade_profissional?.endereco?.complemento)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.imovel_garantia?.matricula_imovel)) {
                qtdCamposGeral -= 1;
            }
            if (nulos.includes(fiador?.imovel_garantia?.certidao_matricula?.nome)) {
                qtdCamposGeral -= 1;
            }
        }


        // console.log(arraylabels);
        console.log("size", size);
        console.log("qtdCamposGeral", qtdCamposGeral);

        //arranjo tecnico até achar solucao definitiva
        if (size == 24 && qtdCamposGeral == 25) {
            return true;
        }
        return size >= qtdCamposGeral;
    }

    const itsFullIsTrue = () => {
        const value = Object.values(campos ?? null);
        return !value.includes(false) && itsFull() && value?.length > 10;
    }

    return (
        <Fragment>
            <div className="p-6">
                <form onSubmit={onSubmit}>
                    {
                        mobile ? <FormularioCadastroFiadorMobile
                            fiador={fiador}
                            campos={campos}
                            handleClick={handleClick}
                            selectSection={selectSection}
                            sections={sections}
                            onChange={onChange}
                            openModal={openModal}
                            itsView={itsView}
                            etapaId={etapaId}
                            onSubmit={onSubmit}
                            rgOrCnhConjuge={rgOrCnhConjuge}
                            rgOrCnh={rgOrCnh}
                            nulos={nulos}
                            nameInterpriseOrServicePublic={nameInterpriseOrServicePublic}
                            existFalse={existFalse}
                            itsFullIsTrue={itsFullIsTrue}
                            handleStatusCadastro={handleStatusCadastro}
                            itsFull={itsFull}
                        /> :
                            <>
                                <div className="bg-white p-10 flex flex-col rounded-lg border-white border h-full w-full" style={{ "boxShadow": "0px 1px 10px rgba(0, 0, 0, 0.2);" }}>

                                    {/* Identificação */}
                                    <div className="border-b border-blue-500">
                                        <TitleValidate
                                            title={'Identificação'}
                                            selectSection={selectSection}
                                            checkSection={sections?.identificacao}
                                            section={0}
                                            itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                        />
                                        <div className="grid grid-cols-2 gap-4 w-full mb-8">
                                            <div className="flex flex-col gap-4">
                                                <InputValidate
                                                    title={`Nome`}
                                                    name={`nome_fiador`}
                                                    value={fiador?.informacoes_basicas?.nome_cliente ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.nome_fiador ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`E-mail`}
                                                    name={`email_fiador`}
                                                    value={fiador?.informacoes_basicas?.email ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.email_fiador ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                    <InputValidate
                                                        title={`Telefone 1`}
                                                        name={`telefone_fiador`}
                                                        value={mask(fiador?.informacoes_basicas?.telefone1, 'telefone') ?? null}
                                                        onClick={handleClick}
                                                        status={itsView ? null : campos.telefone_fiador ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                    <InputValidate
                                                        title={`Telefone 2`}
                                                        name={`telefone2_fiador`}
                                                        value={mask(fiador?.informacoes_basicas?.telefone2, 'telefone') ?? null}
                                                        onClick={handleClick}
                                                        status={itsView ? null : campos.telefone2_fiador ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                </div>
                                                <span className={`font-extrabold text-lg ${fiador?.informacoes_basicas?.rg_cnh_frente_arquivo_id === null ? 'hidden' : ''}`}>Documento de Identificação Oficial com Foto*</span>
                                                <span className={`font-extrabold text-base mt-3 ${fiador?.informacoes_basicas?.rg_cnh_frente_arquivo_id === null ? 'hidden' : ''}`}>Documento: {rgOrCnh ?? 'RG ou CNH'}</span>
                                                <div className="grid grid-cols-1 lg:grid-cols-1 gap-2">
                                                    <InputValidate
                                                        title={`Frente`}
                                                        name={`rg_cnh_frente_fiador`}
                                                        value={fiador?.informacoes_basicas?.rg_cnh_frente?.nome ?? null}
                                                        onClick={handleClick}
                                                        isFile={true}
                                                        hidden={fiador?.informacoes_basicas?.rg_cnh_frente_arquivo_id === null}
                                                        file={fiador?.informacoes_basicas?.rg_cnh_frente?.caminho ?? null}
                                                        status={itsView ? null : campos.rg_cnh_frente_fiador ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                    <InputValidate
                                                        title={`Verso`}
                                                        name={`rg_cnh_verso_fiador`}
                                                        value={fiador?.informacoes_basicas?.rg_cnh_verso?.nome ?? null}
                                                        onClick={handleClick}
                                                        isFile={true}
                                                        hidden={fiador?.informacoes_basicas?.rg_cnh_verso_arquivo_id === null}
                                                        file={fiador?.informacoes_basicas?.rg_cnh_verso?.caminho ?? null}
                                                        status={itsView ? null : campos.rg_cnh_verso_fiador ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-col gap-4">

                                                <InputValidate
                                                    title={`CPF`}
                                                    name={`cpf_fiador`}
                                                    value={mask(fiador?.informacoes_basicas?.cpf, 'cpf_cnpj') ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.cpf_fiador ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                    <InputValidate
                                                        title={`Estado Civil`}
                                                        name={`estado_civil_fiador`}
                                                        value={fiador?.informacoes_basicas?.estado_civil?.descricao ?? null}
                                                        onClick={handleClick}
                                                        status={itsView ? null : campos.estado_civil_fiador ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                    <InputValidate
                                                        title={`Nº de Dependentes`}
                                                        name={`numb_dependentes_fiador`}
                                                        value={fiador?.informacoes_basicas?.qtd_dependentes ?? null}
                                                        onClick={handleClick}
                                                        status={itsView ? null : campos.numb_dependentes_fiador ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                </div>
                                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                    <InputValidate
                                                        title={`Naturalidade`}
                                                        name={`naturalidade_fiador`}
                                                        value={fiador?.informacoes_basicas?.naturalidade ?? null}
                                                        hidden={fiador?.informacoes_basicas?.naturalidade === null}
                                                        onClick={handleClick}
                                                        status={itsView ? null : campos.naturalidade_fiador ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                    {console.log(itsView)}
                                                    <InputValidate
                                                        title={`Nacionalidade`}
                                                        name={`nacionalidade_fiador`}
                                                        value={fiador?.informacoes_basicas?.nacionalidade ?? null}
                                                        hidden={fiador?.informacoes_basicas?.nacionalidade === null}
                                                        onClick={handleClick}
                                                        status={itsView ? null : campos.nacionalidade_fiador ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                    {/* Cônjuge */}
                                    <div className={`border-b border-blue-500 mt-12 ${fiador?.conjuge === null ? 'hidden' : ''}`}>
                                        <TitleValidate
                                            title={'Cônjuge'}
                                            selectSection={selectSection}
                                            checkSection={sections?.conjuge ?? null}
                                            section={1}
                                            itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                        />
                                        <div className="grid grid-cols-2 gap-4 w-full mb-8">
                                            <div className="flex flex-col gap-4">
                                                <InputValidate
                                                    title={`Nome`}
                                                    name={`nome_conjuge`}
                                                    value={fiador?.conjuge?.informacoes_basicas.nome_cliente ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.nome_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                    <InputValidate
                                                        title={`Telefone 1`}
                                                        name={`telefone_conjuge`}
                                                        value={mask(fiador?.conjuge?.informacoes_basicas.telefone1, 'telefone') ?? null}
                                                        onClick={handleClick}
                                                        status={itsView ? null : campos.telefone_conjuge ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                    <InputValidate
                                                        title={`Telefone 2`}
                                                        name={`telefone2_conjuge`}
                                                        value={mask(fiador?.conjuge?.informacoes_basicas.telefone2, 'telefone') ?? null}
                                                        onClick={handleClick}
                                                        status={itsView ? null : campos.telefone2_conjuge ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                </div>
                                                <span className="font-extrabold text-lg">Documento de Identificação Oficial com Foto*</span>
                                                <div className="grid grid-cols-1 lg:grid-cols-1 gap-2">
                                                    <InputValidate
                                                        title={`${rgOrCnhConjuge} - Frente`}
                                                        name={`rg_cnh_frente_conjuge`}
                                                        value={fiador?.conjuge?.informacoes_basicas?.rg_cnh_frente?.nome ?? null}
                                                        onClick={handleClick}
                                                        isFile={true}
                                                        file={fiador?.conjuge?.informacoes_basicas?.rg_cnh_frente?.caminho ?? null}
                                                        status={itsView ? null : campos.rg_cnh_frente_conjuge ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                    <InputValidate
                                                        title={`${rgOrCnhConjuge} - Verso`}
                                                        name={`rg_cnh_verso_conjuge`}
                                                        value={fiador?.conjuge?.informacoes_basicas?.rg_cnh_verso?.nome ?? null}
                                                        onClick={handleClick}
                                                        isFile={true}
                                                        file={fiador?.conjuge?.informacoes_basicas?.rg_cnh_verso?.caminho ?? null}
                                                        status={itsView ? null : campos.rg_cnh_verso_conjuge ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-col gap-4">
                                                <InputValidate
                                                    title={`CPF`}
                                                    name={`cpf_conjuge`}
                                                    value={mask(fiador?.conjuge?.informacoes_basicas.cpf, 'cpf_cnpj') ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.cpf_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <div className={`grid grid-cols-1 lg:grid-cols-2 gap-2 ${fiador?.conjuge?.informacoes_basicas?.naturalidade === null ? 'hidden' : ''}`}>
                                                    <InputValidate
                                                        title={`Naturalidade`}
                                                        name={`naturalidade_conjuge`}
                                                        value={fiador?.conjuge?.informacoes_basicas.naturalidade ?? null}
                                                        hidden={fiador?.conjuge?.informacoes_basicas?.naturalidade === null}
                                                        onClick={handleClick}
                                                        status={itsView ? null : campos.naturalidade_conjuge ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                    <InputValidate
                                                        title={`Nacionalidade`}
                                                        name={`nacionalidade_conjuge`}
                                                        value={fiador?.conjuge?.informacoes_basicas.nacionalidade ?? null}
                                                        hidden={fiador?.conjuge?.informacoes_basicas?.nacionalidade === null}
                                                        onClick={handleClick}
                                                        status={itsView ? null : campos.nacionalidade_conjuge ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                </div>
                                                <InputValidate
                                                    title={`E-mail`}
                                                    name={`email_conjuge`}
                                                    value={fiador?.conjuge?.informacoes_basicas.email ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.email_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2 gap-4 w-full mb-8">
                                            <div className="flex flex-col gap-4">
                                                <div className={`grid grid-cols-1 lg:grid-cols-2 gap-2`}>
                                                    <InputValidate
                                                        title={`Tipo de Atividade Profissional`}
                                                        name={`tipo_atividade_profissional_conjuge`}
                                                        value={fiador?.conjuge?.atividade_profissional?.tipo_atividade?.descricao ?? null}
                                                        onClick={handleClick}
                                                        status={itsView ? null : campos.tipo_atividade_profissional_conjuge ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                    <InputValidate
                                                        title={`Renda Mensal`}
                                                        name={`renda_mensal_conjuge`}
                                                        value={formatarValor(fiador?.conjuge?.atividade_profissional?.renda_mensal, "dinheiro") ?? null}
                                                        onClick={handleClick}
                                                        status={itsView ? null : campos.renda_mensal_conjuge ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                </div>
                                                <InputValidate
                                                    title={'Nome da Empresa'}
                                                    name={`nome_empresa_conjuge`}
                                                    value={fiador?.conjuge?.atividade_profissional?.nome_empresa ?? null}
                                                    onClick={handleClick}
                                                    hidden={fiador?.conjuge?.atividade_profissional?.nome_empresa === null}
                                                    status={itsView ? null : campos.nome_empresa_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Ramo da Atividade`}
                                                    name={`ramo_atividade_conjuge`}
                                                    value={fiador?.conjuge?.atividade_profissional?.ramo_atividade ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos?.ramo_atividade_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    hidden={nulos.includes(fiador?.conjuge?.atividade_profissional?.ramo_atividade) || fiador?.conjuge?.atividade_profissional?.ramo_atividade == null}
                                                />
                                                <div className={`grid grid-cols-1 lg:grid-cols-2 gap-2`}>
                                                    <InputValidate
                                                        title={`Tempo de Empresa`}
                                                        name={`tempo_empresa_conjuge`}
                                                        value={fiador?.conjuge?.atividade_profissional?.tempo_empresa?.descricao ?? null}
                                                        onClick={handleClick}
                                                        hidden={fiador?.conjuge?.atividade_profissional?.tempo_empresa?.descricao === undefined || fiador?.conjuge?.atividade_profissional?.tempo_empresa?.descricao == null}
                                                        status={itsView ? null : campos.tempo_empresa_conjuge ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                    <InputValidate
                                                        title={`Cargo Atual`}
                                                        name={`cargo_atual_conjuge`}
                                                        value={fiador?.conjuge?.atividade_profissional?.cargo_atual ?? null}
                                                        onClick={handleClick}
                                                        hidden={fiador?.conjuge?.atividade_profissional?.cargo_atual === undefined || fiador?.conjuge?.atividade_profissional?.cargo_atual == null}
                                                        status={itsView ? null : campos.cargo_atual_conjuge ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                </div>
                                                <InputValidate
                                                    title={`Último Extrato do Cartão de Crédito`}
                                                    name={`extrato_cartao_credito_conjuge`}
                                                    value={fiador?.conjuge?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    hidden={fiador?.conjuge?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.caminho === undefined}
                                                    file={fiador?.conjuge?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.caminho ?? null}
                                                    status={itsView ? null : campos.extrato_cartao_credito_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <p className={`font-extrabold text-lg text-gray-system-mega-imoveis ml-6`}>{fiador?.conjuge?.atividade_profissional?.arquivo_outro?.caminho !== undefined
                                                    || fiador?.conjuge?.atividade_profissional?.arquivo_contra_cheque?.caminho !== undefined
                                                    || fiador?.conjuge?.atividade_profissional?.arquivo_ctps?.caminho !== undefined
                                                    || fiador?.conjuge?.atividade_profissional?.arquivo_imposto_renda?.caminho !== undefined ? 'Comprovante de Renda*' : ''}</p>
                                                <InputValidate
                                                    title={`Contracheque`}
                                                    name={`contracheque_conjuge`}
                                                    value={fiador?.conjuge?.atividade_profissional?.arquivo_contra_cheque?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    hidden={fiador?.conjuge?.atividade_profissional?.arquivo_contra_cheque?.caminho === undefined}
                                                    file={fiador?.conjuge?.atividade_profissional?.arquivo_contra_cheque?.caminho ?? null}
                                                    status={itsView ? null : campos.contracheque_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Contracheque 2`}
                                                    name={`contracheque2_conjuge`}
                                                    value={fiador?.conjuge?.atividade_profissional?.arquivo_contra_cheque2?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    hidden={fiador?.conjuge?.atividade_profissional?.arquivo_contra_cheque2?.caminho === undefined}
                                                    file={fiador?.conjuge?.atividade_profissional?.arquivo_contra_cheque2?.caminho ?? null}
                                                    status={itsView ? null : campos.contracheque2_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Contracheque 3`}
                                                    name={`contracheque3_conjuge`}
                                                    value={fiador?.conjuge?.atividade_profissional?.arquivo_contra_cheque3?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    hidden={fiador?.conjuge?.atividade_profissional?.arquivo_contra_cheque3?.caminho === undefined}
                                                    file={fiador?.conjuge?.atividade_profissional?.arquivo_contra_cheque3?.caminho ?? null}
                                                    status={itsView ? null : campos.contracheque3_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`CTPS`}
                                                    name={`carteira_trabalho_conjuge`}
                                                    value={fiador?.conjuge?.atividade_profissional?.arquivo_ctps?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    hidden={fiador?.conjuge?.atividade_profissional?.arquivo_ctps?.caminho === undefined}
                                                    file={fiador?.conjuge?.atividade_profissional?.arquivo_ctps?.caminho ?? null}
                                                    status={itsView ? null : campos.carteira_trabalho_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Imposto de Renda`}
                                                    name={`imposto_renda_conjuge`}
                                                    value={fiador?.conjuge?.atividade_profissional?.arquivo_imposto_renda?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    hidden={fiador?.conjuge?.atividade_profissional?.arquivo_imposto_renda?.caminho === undefined}
                                                    file={fiador?.conjuge?.atividade_profissional?.arquivo_imposto_renda?.caminho ?? null}
                                                    status={itsView ? null : campos.imposto_renda_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Extrato Bancário`}
                                                    name={`extrato_bancario_conjuge`}
                                                    value={fiador?.conjuge?.atividade_profissional?.arquivo_extrato_bancario?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    hidden={fiador?.conjuge?.atividade_profissional?.arquivo_extrato_bancario?.caminho === undefined}
                                                    file={fiador?.conjuge?.atividade_profissional?.arquivo_extrato_bancario?.caminho ?? null}
                                                    status={itsView ? null : campos.extrato_bancario_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Extrato Bancário 2`}
                                                    name={`extrato_bancario2_conjuge`}
                                                    value={fiador?.conjuge?.atividade_profissional?.arquivo_extrato_bancario2?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    hidden={fiador?.conjuge?.atividade_profissional?.arquivo_extrato_bancario2?.caminho === undefined}
                                                    file={fiador?.conjuge?.atividade_profissional?.arquivo_extrato_bancario2?.caminho ?? null}
                                                    status={itsView ? null : campos.extrato_bancario2_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Extrato Bancário 3`}
                                                    name={`extrato_bancario3_conjuge`}
                                                    value={fiador?.conjuge?.atividade_profissional?.arquivo_extrato_bancario3?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    hidden={fiador?.conjuge?.atividade_profissional?.arquivo_extrato_bancario3?.caminho === undefined}
                                                    file={fiador?.conjuge?.atividade_profissional?.arquivo_extrato_bancario3?.caminho ?? null}
                                                    status={itsView ? null : campos.extrato_bancario3_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Outro`}
                                                    name={`outro_conjuge`}
                                                    value={fiador?.conjuge?.atividade_profissional?.arquivo_outro?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    hidden={fiador?.conjuge?.atividade_profissional?.arquivo_outro?.caminho === undefined}
                                                    file={fiador?.conjuge?.atividade_profissional?.arquivo_outro?.caminho ?? null}
                                                    status={itsView ? null : campos.outro_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                            </div>
                                            <div className={`flex flex-col gap-4`}>
                                                <InputValidate
                                                    title={`CEP`}
                                                    name={`cep_empresa_atual_conjuge`}
                                                    value={mask(fiador?.conjuge?.atividade_profissional?.endereco?.cep, 'cep') ?? null}
                                                    onClick={handleClick}
                                                    hidden={fiador?.conjuge?.atividade_profissional?.endereco?.cep === undefined}
                                                    status={itsView ? null : campos.cep_empresa_atual_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                    <InputValidate
                                                        title={`Logradouro`}
                                                        name={`logradouro_empresa_atual_conjuge`}
                                                        value={fiador?.conjuge?.atividade_profissional?.endereco?.logradouro ?? null}
                                                        onClick={handleClick}
                                                        hidden={fiador?.conjuge?.atividade_profissional?.endereco?.logradouro === undefined}
                                                        status={itsView ? null : campos.logradouro_empresa_atual_conjuge ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                    <InputValidate
                                                        title={`Número`}
                                                        name={`numero_empresa_atual_conjuge`}
                                                        value={fiador?.conjuge?.atividade_profissional?.endereco?.numero ?? null}
                                                        onClick={handleClick}
                                                        hidden={fiador?.conjuge?.atividade_profissional?.endereco?.numero === undefined}
                                                        status={itsView ? null : campos.numero_empresa_atual_conjuge ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                </div>
                                                <InputValidate
                                                    title={`Bairro`}
                                                    name={`bairro_empresa_atual_conjuge`}
                                                    value={fiador?.conjuge?.atividade_profissional?.endereco?.bairro ?? null}
                                                    onClick={handleClick}
                                                    hidden={fiador?.conjuge?.atividade_profissional?.endereco?.bairro === undefined}
                                                    status={itsView ? null : campos.bairro_empresa_atual_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                    <InputValidate
                                                        title={`Cidade`}
                                                        name={`cidade_empresa_atual_conjuge`}
                                                        value={fiador?.conjuge?.atividade_profissional?.endereco?.cidade ?? null}
                                                        onClick={handleClick}
                                                        hidden={fiador?.conjuge?.atividade_profissional?.endereco?.cidade === undefined}
                                                        status={itsView ? null : campos.cidade_empresa_atual_conjuge ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                    <InputValidate
                                                        title={`Estado`}
                                                        name={`estado_empresa_atual_conjuge`}
                                                        value={fiador?.conjuge?.atividade_profissional?.endereco?.estado ?? null}
                                                        onClick={handleClick}
                                                        hidden={fiador?.conjuge?.atividade_profissional?.endereco?.estado === undefined}
                                                        status={itsView ? null : campos.estado_empresa_atual_conjuge ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                </div>
                                                <InputValidate
                                                    title={`Complemento`}
                                                    name={`complemento_empresa_atual_conjuge`}
                                                    value={fiador?.conjuge?.atividade_profissional?.endereco?.complemento ?? null}
                                                    onClick={handleClick}
                                                    hidden={fiador?.conjuge?.atividade_profissional?.endereco?.complemento === undefined || fiador?.conjuge?.atividade_profissional?.endereco?.complemento === null}
                                                    status={itsView ? null : campos.complemento_empresa_atual_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    {/* Residência Atual */}
                                    <div className={`border-b border-blue-500 mt-12 ${fiador?.endereco === null ? 'hidden' : ''}`}>
                                        <TitleValidate
                                            title={'Residência Atual'}
                                            selectSection={selectSection}
                                            checkSection={sections?.residencia_atual}
                                            section={2}
                                            itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                        />
                                        <div className="grid grid-cols-2 gap-4 w-full mb-8">
                                            <div className="flex flex-col gap-4">
                                                <InputValidate
                                                    title={`Sua Residência é`}
                                                    name={`sua_residencia_atual_e`}
                                                    value={fiador?.residencia_atual?.tipo_residencia?.descricao ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.sua_residencia_atual_e ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Comprovante de Endereço`}
                                                    name={`comprovante_endereco_residencia_atual`}
                                                    value={fiador?.residencia_atual?.arquivo?.nome ?? null}
                                                    isFile={true}
                                                    file={fiador?.residencia_atual?.arquivo?.caminho ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.comprovante_endereco_residencia_atual ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`CEP`}
                                                    name={`cep_residencia_atual`}
                                                    value={mask(fiador?.endereco?.cep, 'cep') ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.cep_residencia_atual ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                    <InputValidate
                                                        title={`Logradouro`}
                                                        name={`logradouro_residencia_atual`}
                                                        value={fiador?.endereco?.logradouro ?? null}
                                                        onClick={handleClick}
                                                        status={itsView ? null : campos.logradouro_residencia_atual ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                    <InputValidate
                                                        title={`Número`}
                                                        name={`numero_residencia_atual`}
                                                        value={fiador?.endereco?.numero ?? null}
                                                        onClick={handleClick}
                                                        status={itsView ? null : campos.numero_residencia_atual ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                </div>

                                            </div>
                                            <div className="flex flex-col gap-4">
                                                <InputValidate
                                                    title={`Complemento`}
                                                    name={`complemento_residencia_atual`}
                                                    value={fiador?.endereco?.complemento ?? null}
                                                    onClick={handleClick}
                                                    hidden={fiador?.endereco?.complemento === null}
                                                    status={itsView ? null : campos.complemento_residencia_atual ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Bairro`}
                                                    name={`bairro_residencia_atual`}
                                                    value={fiador?.endereco?.bairro ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.bairro_residencia_atual ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                    <InputValidate
                                                        title={`Cidade`}
                                                        name={`cidade_residencia_atual`}
                                                        value={fiador?.endereco?.cidade ?? null}

                                                        onClick={handleClick}
                                                        status={itsView ? null : campos.cidade_residencia_atual ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                    <InputValidate
                                                        title={`Estado`}
                                                        name={`estado_residencia_atual`}
                                                        value={fiador?.endereco?.estado ?? null}

                                                        onClick={handleClick}
                                                        status={itsView ? null : campos.estado_residencia_atual ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                </div>
                                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                    <InputValidate
                                                        title={`Valor do Aluguel`}
                                                        name={`valor_aluguel_residencia_atual`}
                                                        value={formatarValor(fiador?.residencia_atual?.valor_aluguel, "dinheiro") ?? null}
                                                        hidden={fiador?.residencia_atual?.valor_aluguel === null}
                                                        onClick={handleClick}
                                                        status={itsView ? null : campos.valor_aluguel_residencia_atual ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                    <InputValidate
                                                        title={`Tempo de Locação`}
                                                        name={`tempo_locacao_residencia_atual`}
                                                        value={fiador?.residencia_atual?.tempo_locacao ?? null}
                                                        hidden={fiador?.residencia_atual?.tempo_locacao === null}
                                                        onClick={handleClick}
                                                        status={itsView ? null : campos.tempo_locacao_residencia_atual ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>





                                    {/* Atividade Profissional */}
                                    <div className={`border-b border-blue-500 mt-12 ${fiador?.atividade_profissional === null ? 'hidden' : ''}`}>
                                        <TitleValidate
                                            title={'Atividade Profissional'}
                                            selectSection={selectSection}
                                            checkSection={sections.atividade_profissional}
                                            section={3}
                                            itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                        />
                                        <div className="grid grid-cols-2 gap-4 w-full mb-8">
                                            <div className="flex flex-col gap-4">
                                                <InputValidate
                                                    title={`Tipo de Atividade Profissional`}
                                                    name={`tipo_atividade_profissional`}
                                                    value={fiador?.atividade_profissional?.tipo_atividade?.descricao ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.tipo_atividade_profissional ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Atividade Profissional`}
                                                    name={`atividade_profissional`}
                                                    value={fiador?.atividade_profissional?.outra_atividade_profissional ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos?.atividade_profissional ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    hidden={nulos.includes(fiador?.atividade_profissional?.outra_atividade_profissional)}
                                                />
                                                <InputValidate
                                                    title={`Ramo da Atividade`}
                                                    name={`ramo_atividade`}
                                                    value={fiador?.atividade_profissional?.ramo_atividade ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos?.ramo_atividade ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    hidden={nulos.includes(fiador?.atividade_profissional?.ramo_atividade)}
                                                />
                                                <InputValidate
                                                    title={`Renda Mensal`}
                                                    name={`renda_mensal`}
                                                    value={formatarValor(fiador?.atividade_profissional?.renda_mensal, "dinheiro") ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.renda_mensal ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Último Extrato do Cartão de Crédito`}
                                                    name={`extrato_cartao_credito`}
                                                    value={fiador?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    hidden={fiador?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.caminho === undefined}
                                                    file={fiador?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.caminho ?? null}
                                                    status={itsView ? null : campos.extrato_cartao_credito ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <p className={`font-extrabold text-lg text-gray-system-mega-imoveis ml-6`}>{fiador?.atividade_profissional?.arquivo_outro?.caminho !== undefined
                                                    || fiador?.atividade_profissional?.arquivo_contra_cheque?.caminho !== undefined
                                                    || fiador?.atividade_profissional?.arquivo_ctps?.caminho !== undefined
                                                    || fiador?.atividade_profissional?.arquivo_imposto_renda?.caminho !== undefined ? 'Comprovante de Renda*' : ''}</p>
                                                <InputValidate
                                                    title={`Contracheque`}
                                                    name={`contracheque`}
                                                    value={fiador?.atividade_profissional?.arquivo_contra_cheque?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    hidden={fiador?.atividade_profissional?.arquivo_contra_cheque?.caminho === undefined}
                                                    file={fiador?.atividade_profissional?.arquivo_contra_cheque?.caminho ?? null}
                                                    status={itsView ? null : campos.contracheque ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Contracheque 2`}
                                                    name={`contracheque2`}
                                                    value={fiador?.atividade_profissional?.arquivo_contra_cheque2?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    hidden={fiador?.atividade_profissional?.arquivo_contra_cheque2?.caminho === undefined}
                                                    file={fiador?.atividade_profissional?.arquivo_contra_cheque2?.caminho ?? null}
                                                    status={itsView ? null : campos.contracheque2 ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Contracheque 3`}
                                                    name={`contracheque3`}
                                                    value={fiador?.atividade_profissional?.arquivo_contra_cheque3?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    hidden={fiador?.atividade_profissional?.arquivo_contra_cheque3?.caminho === undefined}
                                                    file={fiador?.atividade_profissional?.arquivo_contra_cheque3?.caminho ?? null}
                                                    status={itsView ? null : campos.contracheque3 ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`CTPS`}
                                                    name={`carteira_trabalho`}
                                                    value={fiador?.atividade_profissional?.arquivo_ctps?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    hidden={fiador?.atividade_profissional?.arquivo_ctps?.caminho === undefined}
                                                    file={fiador?.atividade_profissional?.arquivo_ctps?.caminho ?? null}
                                                    status={itsView ? null : campos.carteira_trabalho ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Imposto de Renda`}
                                                    name={`imposto_renda`}
                                                    value={fiador?.atividade_profissional?.arquivo_imposto_renda?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    hidden={fiador?.atividade_profissional?.arquivo_imposto_renda?.caminho === undefined}
                                                    file={fiador?.atividade_profissional?.arquivo_imposto_renda?.caminho ?? null}
                                                    status={itsView ? null : campos.imposto_renda ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Extrato Bancário`}
                                                    name={`extrato_bancario`}
                                                    value={fiador?.atividade_profissional?.arquivo_extrato_bancario?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    hidden={fiador?.atividade_profissional?.arquivo_extrato_bancario?.caminho === undefined}
                                                    file={fiador?.atividade_profissional?.arquivo_extrato_bancario?.caminho ?? null}
                                                    status={itsView ? null : campos.extrato_bancario ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Extrato Bancário 2`}
                                                    name={`extrato_bancario2`}
                                                    value={fiador?.atividade_profissional?.arquivo_extrato_bancario2?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    hidden={fiador?.atividade_profissional?.arquivo_extrato_bancario2?.caminho === undefined}
                                                    file={fiador?.atividade_profissional?.arquivo_extrato_bancario2?.caminho ?? null}
                                                    status={itsView ? null : campos.extrato_bancario2 ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Extrato Bancário 3`}
                                                    name={`extrato_bancario3`}
                                                    value={fiador?.atividade_profissional?.arquivo_extrato_bancario3?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    hidden={fiador?.atividade_profissional?.arquivo_extrato_bancario3?.caminho === undefined}
                                                    file={fiador?.atividade_profissional?.arquivo_extrato_bancario3?.caminho ?? null}
                                                    status={itsView ? null : campos.extrato_bancario ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Outro`}
                                                    name={`outro`}
                                                    value={fiador?.atividade_profissional?.arquivo_outro?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    hidden={fiador?.atividade_profissional?.arquivo_outro?.caminho === undefined}
                                                    file={fiador?.atividade_profissional?.arquivo_outro?.caminho ?? null}
                                                    status={itsView ? null : campos.outro ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />

                                                <InputValidate
                                                    title={nameInterpriseOrServicePublic}
                                                    name={`nome_empresa`}
                                                    value={fiador?.atividade_profissional?.nome_empresa ?? null}
                                                    onClick={handleClick}
                                                    hidden={nulos.includes(fiador?.atividade_profissional?.nome_empresa)}
                                                    status={itsView ? null : campos.nome_empresa ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <div className={`grid grid-cols-1 lg:grid-cols-2 gap-2`}>
                                                    <InputValidate
                                                        title={`Tempo de Empresa`}
                                                        name={`tempo_empresa`}
                                                        value={fiador?.atividade_profissional?.tempo_empresa?.descricao ?? null}
                                                        onClick={handleClick}
                                                        hidden={nulos.includes(fiador?.atividade_profissional?.tempo_empresa?.descricao)}
                                                        status={itsView ? null : campos.tempo_empresa ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                    <InputValidate
                                                        title={`Cargo Atual`}
                                                        name={`cargo_atual`}
                                                        value={fiador?.atividade_profissional?.cargo_atual ?? null}
                                                        onClick={handleClick}
                                                        hidden={nulos.includes(fiador?.atividade_profissional?.cargo_atual)}
                                                        status={itsView ? null : campos.cargo_atual ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                </div>

                                            </div>
                                            <div className={`flex flex-col gap-4`}>
                                                <InputValidate
                                                    title={`CEP`}
                                                    name={`cep_empresa_atual`}
                                                    value={mask(fiador?.atividade_profissional?.endereco?.cep, 'cep') ?? null}
                                                    onClick={handleClick}
                                                    hidden={nulos.includes(fiador?.atividade_profissional?.endereco?.cep)}
                                                    status={itsView ? null : campos.cep_empresa_atual ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                    <InputValidate
                                                        title={`Logradouro`}
                                                        name={`logradouro_empresa_atual`}
                                                        value={fiador?.atividade_profissional?.endereco?.logradouro ?? null}
                                                        onClick={handleClick}
                                                        hidden={nulos.includes(fiador?.atividade_profissional?.endereco?.logradouro)}
                                                        status={itsView ? null : campos.logradouro_empresa_atual ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                    <InputValidate
                                                        title={`Número`}
                                                        name={`numero_empresa_atual`}
                                                        value={fiador?.atividade_profissional?.endereco?.numero ?? null}
                                                        onClick={handleClick}
                                                        hidden={nulos.includes(fiador?.atividade_profissional?.endereco?.numero)}
                                                        status={itsView ? null : campos.numero_empresa_atual ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                </div>
                                                <InputValidate
                                                    title={`Bairro`}
                                                    name={`bairro_empresa_atual`}
                                                    value={fiador?.atividade_profissional?.endereco?.bairro ?? null}
                                                    onClick={handleClick}
                                                    hidden={nulos.includes(fiador?.atividade_profissional?.endereco?.bairro)}
                                                    status={itsView ? null : campos.bairro_empresa_atual ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                    <InputValidate
                                                        title={`Cidade`}
                                                        name={`cidade_empresa_atual`}
                                                        value={fiador?.atividade_profissional?.endereco?.cidade ?? null}
                                                        onClick={handleClick}
                                                        hidden={nulos.includes(fiador?.atividade_profissional?.endereco?.cidade)}
                                                        status={itsView ? null : campos.cidade_empresa_atual ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                    <InputValidate
                                                        title={`Estado`}
                                                        name={`estado_empresa_atual`}
                                                        value={fiador?.atividade_profissional?.endereco?.estado ?? null}
                                                        onClick={handleClick}
                                                        hidden={nulos.includes(fiador?.atividade_profissional?.endereco?.estado)}
                                                        status={itsView ? null : campos.estado_empresa_atual ?? null}
                                                        itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                    />
                                                </div>
                                                <InputValidate
                                                    title={`Complemento`}
                                                    name={`complemento_empresa_atual`}
                                                    value={fiador?.atividade_profissional?.endereco?.complemento ?? null}
                                                    onClick={handleClick}
                                                    hidden={nulos.includes(fiador?.atividade_profissional?.endereco?.complemento)}
                                                    status={itsView ? null : campos.complemento_empresa_atual ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    {/* Imóvel em Garantia */}
                                    <div className={`mt-12 ${fiador?.imovel_garantia === null ? 'hidden' : ''}`}>
                                        <TitleValidate
                                            title={'Imóvel em Garantia'}
                                            selectSection={selectSection}
                                            checkSection={sections?.imovel_garantia ?? null}
                                            section={4}
                                            itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                        />
                                        <div className="grid grid-cols-2 gap-4 w-full mb-8">
                                            <InputValidate
                                                title={`Matrícula do Imóvel Quitado e Sem Ônus*`}
                                                name={`matricula_imovel`}
                                                value={fiador?.imovel_garantia?.matricula_imovel ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos.matricula_imovel ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`Certidão de Matrícula Atualizada`}
                                                name={`certidao_matricula`}
                                                value={fiador?.imovel_garantia?.certidao_matricula?.nome ?? null}
                                                onClick={handleClick}
                                                isFile={true}
                                                file={fiador?.imovel_garantia?.certidao_matricula?.caminho ?? null}
                                                status={itsView ? null : campos.certidao_matricula ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(fiador?.ultimo_historico?.status?.id)}
                                            />
                                        </div>
                                    </div>
                                    {/* comentario*/}
                                    <div className={`${itsView ? 'hidden' : ''} mt-5`}>
                                        <p className="font-extrabold text-lg text-gray-system-mega-imoveis">Instruções para reenvio*</p>
                                        <textarea
                                            rows={2}
                                            name={`instrucao_reenvio`}
                                            value={fiador?.instrucao_reenvio}
                                            style={{ maxHeight: "20rem", minHeight: "3.5rem" }}
                                            className="block p-4 w-full h-32 mt-2 text-sm bg-gray-ligth-system-mega-imoveis text-gray-system-mega-imoveis rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none"
                                            placeholder={'Adicione instruções para reenvio'}
                                            onChange={onChange}
                                            required={existFalse}
                                        />
                                    </div>

                                </div>
                                {itsFullIsTrue() ? <div className="w-full flex flex-row gap-4 justify-center items-center">
                                    <div>
                                        <button
                                            type="button"
                                            onClick={() => openModal(true)}
                                            className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs text-white cursor-pointer rounded-lg justify-center items-center`}
                                            style={{ backgroundColor: '#FF3B3B' }}
                                        >
                                            reprovar cadastro
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            type="submit"
                                            onClick={() => handleStatusCadastro("aprovado", etapaId)}
                                            className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs text-white cursor-pointer rounded-lg justify-center items-center`}
                                            style={{ backgroundColor: '#0DCB64' }}
                                        >
                                            aprovar cadastro
                                        </button>
                                    </div>
                                </div> : <div className="w-full flex flex-row gap-4 justify-center items-center">
                                    <div>
                                        <button
                                            type="submit"
                                            className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs border-2 border-blue-mega-imoveis text-blue-dark-mega-imoveis bg-transparent cursor-pointer rounded-lg justify-center items-center`}
                                        >
                                            Salvar e continuar depois
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            type="submit"
                                            disabled={!itsFull() ?? true}
                                            onClick={() => handleStatusCadastro("reenviado", etapaId)}
                                            className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs ${itsFull() ? 'bg-blue-dark-mega-imoveis text-white' : 'bg-gray-300 text-gray-700'}   cursor-pointer rounded-lg justify-center items-center`}
                                        >
                                            enviar para usuário revisar
                                        </button>
                                    </div>
                                </div>
                                }
                            </>
                    }



                </form>
            </div>
        </Fragment>
    );
}

export default FormularioCadastroFiador;