import React, { Fragment, useState } from "react";
import InputSelect from "../../Form/Input/InputSelect";
import './style.css';
import TrocarImovelIcon from "src/assets/icones/trocar-imovel.svg"
import ModalTrocarImovel from "src/components/Funcionalidades/ModalTrocarImovel";
import { formatarValor } from "../../funcoes";

type Props = {
    data?: any,
    key?: number,
    title?: string,
    dataOptions?: any,
    onChange?: any,
    dataForm?: any
}

export default function CardTable({
    data,
    key,
    title,
    dataOptions,
    onChange,
    dataForm
}: Props): JSX.Element {
    const [modalTrocarImovel, setModalTrocaImovel] = useState<boolean>(false);
    const [imovel, setImovel] = useState<any>();

    const modalImovel = () => {
        setModalTrocaImovel(!modalTrocarImovel);
    }

    const fecharModal = () => {
        setModalTrocaImovel(false);
    }

    return (
        <Fragment key={key}>
            <ModalTrocarImovel
                open={modalTrocarImovel}
                isClose={fecharModal}
                setImovelModal={setImovel}
                locatarioId={dataForm?.id}
            />
            <div className="flex flex-col w-full">
                <div className={`text-left font-extrabold text-xl ${title == 'Informações do Imóvel' ? 'title-imovel' : ''}`}>
                    <span className="font-mont-bold">
                        {title}
                    </span>
                    {
                        title == 'Informações do Imóvel' ?
                            <div className="trocar-imovel" onClick={() => modalImovel()}>
                                <img className="icon-delete-client-mobile" alt="icon_crown" src={TrocarImovelIcon} />
                                TROCAR IMÓVEL
                            </div>
                            :
                            ''
                    }
                </div>
                <div>
                    <table className="w-full text-base text-left mt-4">
                        <tbody >
                            <tr className="font-normal">
                                {
                                    data.map((item: any) => {
                                        return (<td className="py-3 px-6">
                                            {item.header}
                                        </td>)
                                    })
                                }
                            </tr>
                            <tr className="text-gray-600">
                                {
                                    data.map((item: any) => {
                                        if (item.header === "Status") {
                                            return (<td className="py-4 px-6 border-t">
                                                <div className="border border-gray-500 h-auto px-6 py-2 flex flex-row justify-center items-center gap-2 rounded-lg">
                                                    <div className="rounded-full bg-yellow-500 w-2 h-2" />
                                                    {item.value}
                                                </div>
                                            </td>)
                                        } else {
                                            return (<td className="py-4 px-6 border-t">
                                                {item.type !== 'button' ?
                                                    imovel != undefined ?
                                                        item.header == "Código do Imóvel" ? imovel?.codigo_imovel ?? item.value
                                                            : item.header == "Valor do Aluguel" ? formatarValor(imovel?.preco_locacao, "dinheiro") ?? item.value
                                                                : item.header == "Endereço" ? imovel?.endereco ?? item.value : item.value : item.value
                                                    : <div className=''>
                                                        {item.type === ''}
                                                        <InputSelect
                                                            name={`sede_id`}
                                                            options={dataOptions}
                                                            className={`bg-white w-full text-gray-900 text-sm border-gray-300 border rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2`}
                                                            colClassname={`w-full`}
                                                            onChange={onChange}
                                                            placeholder={"Selecione"}
                                                            value={dataOptions?.filter((option: any) => (option?.value === dataForm?.sede_id))}
                                                        />
                                                    </div>}

                                            </td>)
                                        }
                                    })
                                }
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </Fragment>

    );
}
